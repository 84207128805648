var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center ma-md-5"},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('h4',{staticClass:"text-h4 font-weight-regular"},[_vm._v("OCR Request")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('v-card',[(_vm.loading)?_c('div',{staticClass:"grey--text text-center py-15"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}),_c('div',{staticClass:"caption my-2"},[_vm._v("Loading OCR request...")])],1):(_vm.currentMatchRequest)?_c('v-card-text',[_c('v-simple-table',{staticClass:"text-right",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.currentMatchRequest.requestUserId && _vm.canShowUserInfo())?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("User ID")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                        name: 'user.view',
                        params: { id: _vm.currentMatchRequest.requestUserId },
                      }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.requestUserName || "-")+" ")])],1)]):_vm._e(),(_vm.currentMatchRequest.ocrCardId && _vm.canShowNidOcrCard())?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("OCR Card ID")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                        name: 'nid-ocr-card.single',
                        params: { requestId: _vm.currentMatchRequest.ocrCardId },
                      }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.ocrCardId || "-")+" ")])],1)]):_vm._e(),_vm._l((_vm.items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-left value-cell"},[_vm._v(" "+_vm._s(_vm.emptyChecker(item.value(_vm.currentMatchRequest)))+" ")])])}),_c('tr',[_c('td',[_vm._v("Content")]),_c('td',{staticClass:"py-2"},[_c('img',{staticStyle:{"max-height":"250px","width":"100%","object-fit":"contain","object-position":"center left"},attrs:{"alt":"content-image","src":("data:" + (_vm.currentMatchRequest.mimeType) + ";base64," + (_vm.currentMatchRequest.imageBase64))}})])]),(_vm.currentMatchRequest.parseRequestId && _vm.canShowParseRequest())?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Parse Request ID")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                        name: 'parse-request.single',
                        params: { id: _vm.currentMatchRequest.parseRequestId },
                      }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.parseRequestId || "-")+" ")])],1)]):_vm._e()],2)]},proxy:true}])})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }