<template>
  <v-container class="text-center ma-md-5">
    <v-row>
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">OCR Request</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="10" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading OCR request...</div>
          </div>

          <v-card-text v-else-if="currentMatchRequest">
            <v-simple-table class="text-right">
              <template v-slot:default>
                <tbody>
                  <tr v-if="currentMatchRequest.requestUserId && canShowUserInfo()">
                    <td class="text-right" width="200px">User ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'user.view',
                          params: { id: currentMatchRequest.requestUserId },
                        }"
                      >
                        {{ currentMatchRequest.requestUserName || "-" }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="currentMatchRequest.ocrCardId && canShowNidOcrCard()">
                    <td class="text-right" width="200px">OCR Card ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'nid-ocr-card.single',
                          params: { requestId: currentMatchRequest.ocrCardId },
                        }"
                      >
                        {{ currentMatchRequest.ocrCardId || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-for="item in items" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-left value-cell">
                      {{ emptyChecker(item.value(currentMatchRequest)) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Content</td>
                    <td class="py-2">
                      <img
                        alt="content-image"
                        :src="`data:${currentMatchRequest.mimeType};base64,${currentMatchRequest.imageBase64}`"
                        style="
                          max-height: 250px;
                          width: 100%;
                          object-fit: contain;
                          object-position: center left;
                        "
                      />
                    </td>
                  </tr>
                  <tr v-if="currentMatchRequest.parseRequestId && canShowParseRequest()">
                    <td class="text-right" width="200px">Parse Request ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'parse-request.single',
                          params: { id: currentMatchRequest.parseRequestId },
                        }"
                      >
                        {{ currentMatchRequest.parseRequestId || "-" }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { OCR_REQUEST_SINGLE } from "@/constants/api";

export default {
  name: "MatchRequest",
  metaInfo: { title: "OCR Request" },
  components: {},
  data() {
    return {
      loading: false,
      currentMatchRequest: null,
      items: [
        {
          name: "Requested At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY hh:mm:ss A"),
        },
        { name: "MD5 Sum", value: (item) => item.md5sum },
        { name: "Type", value: (item) => item.type },
        { name: "Content Type", value: (item) => item.mimeType },
        { name: "File Name", value: (item) => item.fileName },
      ],
    };
  },
  mounted() {
    this.fetchMatchRequest();
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    async fetchMatchRequest() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          `${OCR_REQUEST_SINGLE}?md5sum=${this.$route.params.md5sum}`
        );
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.currentMatchRequest = response.data.data;
      } catch (err) {
        this.$iziToast.showError(err);
        this.$router.back();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-img {
  img {
    max-width: 300px;
  }
}
</style>
